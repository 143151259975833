<template>
  <div>
    <Loading :show="loading" :showingCallback="function () { product = null; }" :delay="50" class="w-8 mx-auto"/>

    <div v-if="product">
      <h2>{{ product.name }}</h2>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-x-6 gap-y-8">
        <div class="lg:col-span-3">
          <div v-if="product.files.length > 0" class="h-72 md:h-120">
            <FileGallery v-model="showFiles" :files="product.files" :index="0" :maxPreview="maxPreview"/>
          </div>
          <img v-else :src="require('@/assets/no-image.png')" class="h-72 mx-auto rounded-lg" style="aspect-ratio: 1">
        </div>

        <div class="lg:col-span-2">
          <div v-if="product.variants.length > 1" class="flex gap-x-2 mb-4">
            <div>{{ product.variantType[0].toUpperCase() + product.variantType.slice(1) }}:</div>
            <select v-model="selectedVariant" class="ring-1 ring-gray-500 rounded-sm">
              <option v-for="(variant, index) in product.variants" :key="index" :value="index">{{ variant.name }}</option>
            </select> 
          </div>

          <div class="flex justify-between items-end">
            <div>
              <div class="text-2xl">€ {{ product.variants[selectedVariant].price.toFixed(2).replace(/[.,]0+$/, "") }}</div>

              <div v-if="product.variants[selectedVariant].available === undefined" class="text-yellow-500">{{ $t('noAvailabilityInfo') }}</div>
              <div v-else-if="product.variants[selectedVariant].available > 0" class="text-green-600">{{ $t('available') }}</div>
              <div v-else class="text-red">{{ $t('notAvailable') }}</div>
            </div>

            <a :href="mailLink">
              <Button class="ml-4 md:ml-0">
                {{ $t('order') }}
                <template v-slot:iconRight>
                  <ChevronRightIcon/>
                </template>
              </Button>
            </a>
          </div>

          <div v-if="product.description" class="mt-8">
            <div v-html-sanitized="product.description" class="rendered"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="errorMessage" class="text-red">{{ errorMessage }}</div>
  </div>
</template>

<script>
import { ExclamationIcon, ChevronRightIcon } from '@heroicons/vue/outline'
import Button from '@/components/Button'
import Loading from '@/components/Loading'
import FileGallery from '@/components/FileGallery'

export default {
  name: 'Product',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    ExclamationIcon,
    ChevronRightIcon,
    Button,
    Loading,
    FileGallery
  },
  props: {
    card: Boolean,
    productId: String,
    alignButtons: {
      type: String,
      default: 'left'
    }
  },
  emits: ['close'],
  data () {
    return {
      loading: true,
      product: null,
      errorMessage: null,
      showFiles: true,
      selectedVariant: 0
    }
  },
  watch: {
    productId (value) {
      this.getProduct()
    }
  },
  computed: {
    maxPreview () {
      if (this.$tailwind.breakpoints.lg) {
        return 5
      }
      if (this.$tailwind.breakpoints.sm) {
        return 3
      }
      return 4
    },
    mailLink () {
      let link = `mailto:yne@ynforma.be?subject=Bestelling ${this.product.name}`
      if (this.product.variants.length > 1) {
        link += ` ${this.product.variantType[0].toUpperCase() + this.product.variantType.slice(1)} ${this.product.variants[this.selectedVariant].name}`
      }
      return link
    }
  },
  methods: {
    getProduct () {
      this.loading = true
      this.$axios.get(`/products/${this.productId}`, {
        params: {
          populate: 'files' // TODO: check if possible to only get file MIME type.
        }
      })
        .then(res => {
          this.loading = false
          this.errorMessage = null
          this.product = res.data
          this.selectedVariant = 0
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = this.$axiosErrorHandler(err)
        })
    },
  },
  created () {
    this.getProduct()
  }
}
</script>

<i18n>
{
  "nl": {
    "available": "Op voorraad",
    "notAvailable": "Geen voorraad",
    "noAvailabilityInfo": "Voorraad ongekend",
    "order": "Bestel"
  }
}
</i18n>
