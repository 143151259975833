<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta name="description" :content="$t('head.meta.description')"/>
    <meta name="keywords" :content="$t('head.meta.keywords')"/>
    <meta property="og:title" :content="$t('head.title')">
    <meta property="og:description" :content="$t('head.meta.description')">
  </Head>

  <BlackWhiteTemplate :title="$t('acupuncture')">
    <div class="py-8"  :class="{ 'container': $tailwind.breakpoints.lg }">
      <div class="grid grid-cols-1 lg:grid-cols-3 items-start gap-x-10 gap-y-8">
        <div class="lg:col-span-2" :class="{ 'container': !$tailwind.breakpoints.lg }">
          <p>{{ $t('intro') }}</p>
        </div>

        <div class="hidden lg:block lg:row-span-4 self-stretch">
          <Rates class="sticky top-8"></Rates>
        </div>

        <div class="lg:col-span-2 lg:-ml-6">
          <div class="bg-gray-100 md:shadow-md lg:rounded-xl border-b-4 border-beige-light xl:w-max">
            <div class="py-8 lg:py-6" :class="{ 'container': !$tailwind.breakpoints.lg }">
              <div class="lg:px-6">
                <h3 class="text-red">{{ $t('dangerousTitle') }}</h3>
                <p>{{ $t('dangerous') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-left bg-gray-100 md:shadow-md mb-8 rounded-r-xl border-b-4 border-beige-light md:flex items-center" data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
      <div class="order-1 md:order-2 px-4 md:pl-6 lg:pr-6 pt-8 md:pb-8 lg:py-6 mb-4 md:mb-0">
        <h3 class="text-red">{{ $t('forTitle') }}</h3>
        <p>{{ $t('for1') }}</p>
        <p>{{ $t('for2') }}</p>
        <p>{{ $t('for3') }}</p>
      </div>
      <img :src="require('@/assets/dog-acupuncture-1a.jpg')" class="flex-grow object-cover md:mb-0 md:order-1 md:w-2/5 lg:w-auto md:h-132 lg:h-96" :alt="$t('altDogHydrotherapy')">
    </div>

    <div class="container lg:hidden pb-8">
      <Rates></Rates>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import Button from '@/components/Button'

// Define rates in separate component as will need to be used twice in different places depending on screen size.
const Rates = {
  template: `
    <div class="p-4 shadow-md bg-gray-100 rounded-lg">
      <h3 class="text-red">{{ $tc('rate', 2) }}</h3>
      <h4 class="italic mb-2">{{ $t('rateATitle') }}</h4>
      <p class="text-left text-gray-500">{{ $t('rateA1') }}</p>
      <h4 class="italic mb-0">{{ $t('rateBTitle') }}</h4>
      <p class="text-left text-base text-gray-500 mb-2">{{ $t('rateBNote') }}</p>
      <p class="text-left text-gray-500">
        {{ $t('rateB1') }}<br>
      </p>
      <h4 class="italic mb-0">{{ $t('rateCTitle') }}</h4>
      <p class="text-left text-base text-gray-500 mb-2">{{ $t('rateCNote') }}</p>
      <p class="text-left text-gray-500">
        {{ $t('rateC1') }}
      </p>
    </div>
  `,
  i18n: {
    messages: {
      nl: {
        "rateATitle": "Acupunctuur",
        "rateA1": "Per sessie (30 min): €\xa040",
        "rateBTitle": "Combo acupunctuur + hydro/fysio",
        "rateBNote": "Acupunctuur in combinatie met hydro- en/of fysiotherapie.",
        "rateB1": "Per sessie (45-60 min): €\xa070",
        "rateCTitle": "Eerste sessie",
        "rateCNote": "Tijdens de eerste consultatie nemen we voldoende tijd om de behandeling te bespreken.",
        "rateC1": "Eerste sessie (45-60 min): €\xa050"
      }
    }
  }
}

export default {
  name: 'Acupuncture',
  components: {
    Head,
    ChevronRightIcon,
    BlackWhiteTemplate,
    Button,
    Rates
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Acupunctuur - Yn'forma",
      "meta": {
        "description": "Acupunctuur voor kat en hond. Gevestigd te Gooik.",
        "keywords": "acupunctuur, acupressuur, naalden, gooik, oetingen, yn'forma, ynforma, honden, hond, katten, kat, huisdier, huisdieren"
      }
    },
    "acupuncture": "Acupunctuur",
    "intro": "Bij acupunctuur plaatsen we naalden op specifieke punten in het lichaam die pijnstillend, stimulerend of ontstekingsremmend werken. De combinatie van acupunctuur en fysiotherapie leidt dikwijls tot sterkere resultaten en kan tevens helpen bij onrust, allergieën, braken en ouderdomsklachten.",
    "dangerousTitle": "Is acupunctuur pijnlijk of gevaarlijk?",
    "dangerous": "Acupunctuur is een uiterst veilige methode van behandelen die geen blijvende bijwerkingen heeft. Vrijwel alle dieren ondergaan een acupunctuurbehandeling zonder problemen. Vaak werkt het zelfs ontspannend!",
    "forTitle": "Voor wie?",
    "for1": "De toepassingsmogelijkheden van acupunctuur zijn zeer breed. Acupunctuur kan een behandeling op zich zijn, maar kan evengoed gecombineerd worden met Westerse geneeskunde om op die manier een nog groter effect te verkrijgen.",
    "for2": "Voor orthopedische of neurologische problemen kan acupunctuur ook gecombineerd worden met fysiotherapie. Een combinatie van deze 2 leidt tot sterke resultaten!",
    "for3": "Acupunctuur kan in het bijzonder nuttig zijn bij spier- en peesproblemen, braken, allergieën, blaasproblemen, verlammingsverschijnselen, ouderdomsklachten en angst."
  }
}
</i18n>
