<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta name="description" :content="$t('head.meta.description')"/>
    <meta name="keywords" :content="$t('head.meta.keywords')"/>
    <meta property="og:title" :content="$t('head.title')">
    <meta property="og:description" :content="$t('head.meta.description')">
  </Head>

  <BlackWhiteTemplate :title="$t('nutrition')" title-size="sm">
    <div class="container py-8">
      <p>{{ $t('content1') }}</p>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'

export default {
  name: 'Nutrition',
  components: {
    Head,
    BlackWhiteTemplate
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Voedingsavies - Yn'forma",
      "meta": {
        "description": "Voedingsadvies voor kat en hond. Gevestigd te Gooik.",
        "keywords": "voedingsadvies, voeding, nutritie, gooik, oetingen, yn'forma, ynforma, honden, hond, katten, kat, huisdier, huisdieren"
      }
    },
    "nutrition": "Voedingsadvies",
    "content1": "Als gediplomeerd voedingsadviseur helpen we u de beste keuze maken voor uw huisdier tussen het ruime aanbod aan brokken, verse voeding, diepvries- en BARF-voeding. Heeft uw huisdier soms overgewicht, artrose, groeipijnen of allergieën, en weet u niet welke voeding het meest geschikt is? Samen zoeken we naar de beste oplossing zodat uw viervoeter zich zo snel mogelijk terug beter voelt!"
  }
}
</i18n>
