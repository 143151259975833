<template>
  <div class="container flex justify-center items-end">
    <img :src="require('@/assets/rabbit-side-1.jpg')" class="hidden xl:block h-72" alt="">
    <div>
      <img :src="require('@/assets/dog-front-1.jpg')" class="w-full max-w-sm -mb-10 mx-auto" style="aspect-ratio: 530/400;" alt=""> <!-- Use aspect ratio instead of height to avoid CLS as width is unpredictable on small screens. -->
      <div class="relative top-10 max-w-xl px-4 py-8 sm:px-8 sm:py-12 mx-auto shadow-md bg-beige-light rounded-lg text-center">
        <i18n-t keypath="welcome" tag="h2" class="text-3xl mb-4">
          <template v-slot:ynforma>
            <span class="text-red">Yn'forma!</span>
          </template>
        </i18n-t>
        <div class="text-xl mb-4 md:mb-8 text-gray-600">{{ $t('tagline') }}</div>
        <a href="https://maps.app.goo.gl/V6e7FsxBSA7WjJT16" target="_blank" rel="noreferrer">
          <div class="flex justify-center items-center text-gray-600">
            <LocationMarkerIcon class="h-5 mr-1"/>
            <span>Gooik</span>
          </div>
        </a>
      </div>
    </div>
    <img :src="require('@/assets/cat-side-1.jpg')" class="hidden xl:block h-72" alt="">
  </div>

  <div class="bg-white pt-10">
    <div class="container py-8">
      <h2 class="font-hand text-red underline decoration-3 text-center">{{ $t('services') }}</h2>
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 justify-center mb-4">
        <HoverImage v-for="(service, index) in services" :key="service.title" :title="service.title" :path="service.path" :hash="service.hash" :img="service.img" :imgAlign="service.imgAlign" class="text-white" data-aos="fade-up" data-aos-anchor-placement="top-bottom" :data-aos-anchor="$tailwind.breakpoints.md ? 'html' : ''" :data-aos-delay="$tailwind.breakpoints.md ? 50 * index : 0">
          {{ service.description }}
        </HoverImage>
      </div>
      <localized-link :to="{ path: '/diensten' }">
        <Button>
          {{ $t('allServices') }}
          <template v-slot:iconRight>
            <ChevronRightIcon/>
          </template>
        </Button>
      </localized-link>

      <h2 class="font-hand text-red underline decoration-3 text-center">{{ $t('aboutHeader') }}</h2>
      <div class="md:flex justify-center">
        <FadingImages :imgs="[require('@/assets/dog-fitness-14-edited-1.jpg'), require('@/assets/yne-cheetah-1.jpg'), require('@/assets/yne-pool-1.jpg')]" :alt="[$t('altYneFitness'), $t('altYneCheetah'), $t('altYnePool')]" class="h-96 sm:h-120 md:h-auto mb-4 md:mb-0 md:w-96 md:order-2 rounded-xl" :arrows="false"></FadingImages>
        <div class="md:order-1 md:mr-6">
          <p class="md:max-w-sm text-justify">{{ $t('aboutContent') }}</p>

          <localized-link :to="{ path: '/over' }">
            <Button>
              {{ $t('readMore') }}
              <template v-slot:iconRight>
                <ChevronRightIcon/>
              </template>
            </Button>
          </localized-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LocationMarkerIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import HoverImage from '@/components/HoverImage'
import FadingImages from '@/components/FadingImages'
import Button from '@/components/Button'

export default {
  name: 'Home',
  components: {
    LocationMarkerIcon,
    ChevronRightIcon,
    HoverImage,
    FadingImages,
    Button
  },
  computed: {
    services () {
      return [{
        title: this.$t('hydrotherapy'),
        description: this.$t('hydrotherapyDescription'),
        path: '/hydrotherapie',
        img: require('@/assets/dog-hydro-4b.jpg')
      }, {
        title: this.$t('physiotherapy'),
        description: this.$tailwind.breakpoints.sm ? this.$t('physiotherapyDescriptionSm') : this.$t('physiotherapyDescription'),
        path: '/fysiotherapie',
        img: require('@/assets/dog-fitness-9a.jpg')
      }, {
        title: this.$t('dogFitness'),
        description: this.$t('dogFitnessDescription'),
        path: '/hondenfitness',
        img: require('@/assets/dog-fitness-18.jpg'),
        imgAlign: this.$tailwind.breakpoints.lg ? 'bg-bottom' : 'bg-center'
      }, {
        title: this.$t('acupuncture'),
        description: this.$t('acupunctureDescription'),
        path: '/acupunctuur',
        img: require('@/assets/dog-acupuncture-1a.jpg')
      }, {
        title: this.$t('groomingSalon'),
        description: this.$t('groomingSalonDescription'),
        path: '/trimsalon',
        img: require('@/assets/dog-trimming-6.jpg')
      }
      /*, {
        title: this.$t('puppyTraining'),
        description: this.$t('puppyTrainingDescription'),
        path: '/puppytraining',
        img: require('@/assets/puppy-training-1a.jpg')
      }*/
      ]
    }
  }
}
</script>

<i18n>
{
  "nl": {
    "welcome": "Welkom bij {ynforma}",
    "tagline": "Zorg en begeleiding voor uw huisdieren",
    "services": "Onze diensten",
    "hydrotherapy": "Hydrotherapie",
    "hydrotherapyDescription": "Conditietraining of revalidatie",
    "physiotherapy": "Fysiotherapie",
    "physiotherapyDescription": "Oefentherapie, massage en meer",
    "physiotherapyDescriptionSm": "Oefentherapie, massage, lasertherapie en meer",
    "groomingSalon": "Trimsalon",
    "groomingSalonDescription": "Wassen, drogen, in model zetten",
    "dogFitness": "Hondenfitness",
    "dogFitnessDescription": "Spierontwikkeling en conditie",
    "acupuncture": "Acupunctuur",
    "acupunctureDescription": "Aanvullend en rustgevend",
    "puppyTraining": "Puppytraining",
    "puppyTrainingDescription": "Socialisatie en coördinatie",
    "allServices": "Bekijk alle diensten",
    "aboutHeader": "Over Yn'forma",
    "aboutContent": "Yn’forma staat in voor de algemene gezondheid en het welzijn van de dieren. Een gezonde vacht, aangepaste voeding, fysieke fitheid en een positieve mens-dierrelatie zijn cruciaal voor een gelukkig dier. Yn’forma richt zich tot diereigenaars die de gezondheid van hun dier voorop willen zetten. Onze missie is dan ook om via professionele ondersteuning en advies het welzijn van het dier te optimaliseren.",
    "readMore": "Verder lezen"
  }
}
</i18n>
