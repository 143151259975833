<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta property="og:title" :content="$t('head.title')">
  </Head>

  <BlackWhiteTemplate :title="$t('privacyPolicy')" :img="require('@/assets/rabbit-side-2.jpg')" title-size="xs">
    <div class="container py-8">
      <p>{{ $t('intro') }}</p>
      
      <h3 class="text-red mt-8">{{ $t('companyDetails') }}</h3>
      Yn'forma<br>
      <a href="https://maps.app.goo.gl/V6e7FsxBSA7WjJT16" target="_blank" rel="noreferrer">
        Kerkhofstraat 10<br>
        1755 Gooik<br>
        België<br>
      </a><br>
      <a href="mailto:yne@ynforma.be">yne@ynforma.be</a><br>
      <a href="tel:+32-471-108-278">0471/108.278</a><br><br>
      {{ $t('vat') }} BE0771894326

      <h3 class="text-red mt-8">{{ $t('whichDataTitle') }}</h3>
      <p>{{ $t('whichData1') }}</p>
      <p>{{ $t('whichData2') }}</p>

      <h3 class="text-red mt-8">{{ $t('cookiesTitle') }}</h3>
      <p>{{ $t('cookies1') }}</p>
      <p>{{ $t('cookies2') }}</p>

      <h3 class="text-red mt-8">{{ $t('sharingTitle') }}</h3>
      <p>{{ $t('sharing1') }}</p>
      <p>{{ $t('sharing2') }}</p>

      <h3 class="text-red mt-8">{{ $t('rightsTitle') }}</h3>
      <p>{{ $t('rights1') }}</p>
      <p>{{ $t('rights2') }}</p>

      <br>
      <p class="italic">{{ $t('lastModified') }}: 06/05/2023.</p>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'

export default {
  name: 'NotFound',
  components: {
    Head,
    BlackWhiteTemplate
  }
}
</script>

<i18n>
{
   
  "nl": {
    "head": {
      "title": "Privacyverklaring - Yn'forma",
    },
    "privacyPolicy": "Privacyverklaring",
    "intro": "Bij Yn'forma hechten we veel belang aan uw privacy. Deze privacyverklaring werd opgesteld met het doel transparant te zijn over de manier waarop we uw gegevens verwerken.",
    "companyDetails": "Bedrijfsgegevens",
    "vat": "BTW",
    "whichDataTitle": "Welke gegevens verzamelen we over u en waarom?",
    "whichData1": "Wanneer u op onze webshop een bestelling plaatst, vult u uw naam, contactgegevens en adres in. Deze worden door ons opgeslagen en gebruikt om u de bestelling te bezorgen.",
    "whichData2": "Daarnaast worden geen enkele andere gegevens over u verzameld door onze website. Wij maken bijvoorbeeld geen gebruik van webanalysesoftware of tracking cookies.",
    "cookiesTitle": "Cookies",
    "cookies1": "We gebruiken een cookie om uw winkelwagen te onthouden. Op die manier kan u onze webshop op een later moment opnieuw bezoeken zonder uw winkelwagen te verliezen. De inhoud van uw winkelwagen blijft lokaal op uw toestel en wordt niet met onze server gedeeld zolang u de bestelling niet plaatst.",
    "cookies2": "Verder gebruiken wij geen andere cookies, dus ook geen tracking cookies.",
    "sharingTitle": "Met wie delen we uw gegevens?",
    "sharing1": "Wanneer u op onze webshop een bestelling plaatst en kiest voor thuislevering, worden uw naam, contactgegevens en adres gedeeld met het postbedrijf dat we gebruiken voor de levering, namelijk bpost.",
    "sharing2": "Daarnaast delen wij uw gegevens met geen enkele andere entiteit.",
    "rightsTitle": "Wat zijn uw rechten?",
    "rights1": "Als gebruiker van onze website heeft u verschillende rechten op het vlak van uw gegevensverwerking. U heeft onder meer recht op inzage (het inkijken van de gegevens die we over u verzamelen), recht om te worden vergeten (het verwijderen van uw gegevens uit onze systemen), recht om klacht in te dienen (dit kan u doen bij de Privacycommissie), recht om de toestemming in te trekken om uw gegevens te blijven verwerken en recht op beperking van de verwerking.",
    "rights2": "Indien u één van deze rechten wil inroepen, kan u ons contacteren via de hierboven vermelde bedrijfsgegevens.",
    "lastModified": "Laatste wijziging"
  }
}
</i18n>
