<template>
  <div class="container flex justify-between items-end">
    <h1 class="first:mt-8 font-hand text-white" :class="{ 'title-xs': titleSize === 'xs', 'title-sm': titleSize === 'sm' }">{{ title }}</h1> <!-- TODO: don't have variable to reduce size, but do automatically based on content. -->
    <img :src="img" class="hidden md:block h-52" alt="">
  </div>

  <div class="bg-white flex-grow">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BlackWhiteTemplate',
  props: {
    title: String,
    titleSize: {
      type: String,
      default: 'md'
    },
    img: {
      type: String,
      default: require('@/assets/dog-side-1.jpg')
    }
  }
}
</script>

<style lang="postcss">
h1.font-hand.title-xs {
  @apply text-5xl md:text-6xl lg:text-7xl;
}

h1.font-hand.title-sm {
  @apply text-6xl md:text-7xl;
}
</style>

<!-- Don't define i18n here or scope of components in the slot will not be used. See separate issue referenced at the bottom of https://githubmemory.com/repo/intlify/vue-i18n-next/issues/708. -->
