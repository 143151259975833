<template>
  <div v-if="modelValue && (!fullScreen || $route.hash === '#' + $t('gallery'))" class="flex h-full flex-col items-center" @click="closeIfClickedOutside($event.target)" :class="{ 'bg-gray-100 bg-opacity-80 backdrop-filter backdrop-blur-lg': fullScreen && transparent, 'bg-white': fullScreen && !transparent, 'fixed inset-0 z-50 p-4': fullScreen }">
    <div class="flex-grow"></div>

    <div class="min-h-0">
      <div class="flex justify-center h-full" :class="{ 'cursor-pointer': !fullScreen }" @click="openNested">
        <img ref="file" v-if="files[iIndex].mimeType.startsWith('image')" :src="`/api/files/${files[iIndex].id}/content`" class="h-full rounded-md">

        <video ref="file" v-else-if="files[iIndex].mimeType.startsWith('video')" controls autoplay playsinline :key="files[iIndex].id" class="h-full rounded-md"> <!-- Key required here or content not substituted. To investigate why. -->
          <source :src="`/api/files/${files[iIndex].id}/content`">
        </video>

        <a ref="file" :href="`/api/files/${files[iIndex].id}/content`" target="_blank" @click="$event.stopPropagation()" v-else class="flex flex-col text-center justify-center group w-48 h-48" :class="{ 'bg-white rounded-md p-4': transparent }">
          <DocumentDownloadIcon class="h-6 mt-2 mb-3 group-hover:text-gray-500 flex-shrink-0"/>
          <span class="overflow-hidden">{{ files[iIndex].name }}</span>
        </a>
      </div>
    </div>

    <div class="flex-grow"></div>

    <div>
      <slot name="description" :file="files[iIndex]"/>
    </div>

    <div ref="gallery" v-show="gallery && files.length > 1" class="mt-4 flex gap-2 items-center">
      <button v-if="files.length > 1" @click="iIndex = iIndex - 1" :disabled="iIndex === 0" type="button" class="h-8 w-8 rounded-full flex justify-center items-center" :class="{ 'text-gray-500' : iIndex === 0 }">
        <ChevronLeftIcon class="h-5"/>
      </button>

      <div v-for="fileIndex in range" :key="fileIndex" class="relative w-16 md:w-24 h-16 md:h-24 rounded-lg ring-1 ring-gray-300 cursor-pointer" @click="iIndex = fileIndex" :class="{ 'ring-2 ring-gray-500': fileIndex === iIndex }">
        <img v-if="files[fileIndex].mimeType.startsWith('image')" :src="`/api/files/${files[fileIndex].id}/content`" class="w-full h-full object-cover rounded-lg">

        <div v-else-if="files[fileIndex].mimeType.startsWith('video')" class="group w-full h-full">
          <video playsinline class="w-full h-full object-cover rounded-lg">
            <source :src="`/api/files/${files[fileIndex].id}/content`">
          </video>

          <div class="absolute inset-0 h-14 sm:h-20 w-14 sm:w-20 m-auto max-h-full max-w-full text-gray-400 group-hover:text-white opacity-50 group-hover:opacity-80">
            <PlayIcon class="h-full"/>
          </div>
        </div>

        <div v-else class="flex flex-col rounded-lg text-center justify-center group min-h-0 bg-white w-full h-full">
          <DocumentDownloadIcon class="h-4 sm:h-6 mt-1 sm:mt-2 mb-1 sm:mb-3 group-hover:text-gray-500 flex-shrink-0"/>
          <span class="overflow-hidden text-xs sm:text-base">{{ files[fileIndex].name }}</span>
        </div>
      </div>

      <button v-if="files.length > 1" @click="iIndex = iIndex + 1" :disabled="iIndex === files.length - 1" type="button" class="h-8 w-8 rounded-full flex justify-center items-center" :class="{ 'text-gray-500' : iIndex === files.length - 1 }">
        <ChevronRightIcon class="h-5"/>
      </button>
    </div>

    <button v-if="fullScreen" ref="closeIcon" type="button" @click="$emit('update:modelValue', false)" class="absolute top-4 right-4 h-8 w-8 text-white hover:text-black bg-gray-400 hover:bg-white bg-opacity-50 hover:bg-opacity-80 rounded-full">
      <XIcon class="h-6 mx-auto"/>
    </button>
  </div>

  <FileGallery v-if="!fullScreen" v-model="nested" :files="files" :index="iIndex" fullScreen/>
</template>

<script>
import { XIcon, ChevronLeftIcon, ChevronRightIcon, DocumentDownloadIcon } from '@heroicons/vue/outline'
import { PlayIcon } from '@heroicons/vue/solid'

export default {
  name: 'FileGallery',
  components: {
    XIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    DocumentDownloadIcon,
    PlayIcon
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: Boolean,
    files: Array,
    index: {
      type: Number,
      default: 0
    },
    gallery: {
      type: Boolean,
      default: true
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    maxPreview: {
      type: Number,
      default: -1
    }
  },
  data () {
    return {
      iIndex: 0,
      nested: false
    }
  },
  watch: {
    $route (newVal, oldVal) {
      if (this.fullScreen) {
        if (oldVal.hash === '#' + this.$t('gallery') && newVal.hash !== '#' + this.$t('gallery')) {
          this.$emit('update:modelValue', false)
        }
        if (oldVal.hash !== '#' + this.$t('gallery') && newVal.hash === '#' + this.$t('gallery')) {
          this.$emit('update:modelValue', true)
        }

        if (newVal.hash === '#' + this.$t('gallery')) {
          document.body.style.overflowY = 'hidden'
        } else {
          document.body.style.overflowY = 'auto'
        } 
      }
    },
    modelValue (val) {
      this.iIndex = this.index
      if (this.fullScreen) {
        if (val) {
          this.$router.push({
            ...this.$route,
            hash: '#' + this.$t('gallery')
          })
        } else if (this.$route.hash === '#' + this.$t('gallery')) {
          if (history.state.back) {
            this.$router.go(-1)
          } else {
            this.$router.replace({
              ...this.$route,
              hash: null
            })
          }
        }
      }
    },
    index (value) {
      this.iIndex = value
    }
  },
  computed: {
    transparent () {
      return this.$tailwind.breakpoints.lg
    },
    iMaxPreview () {
      if (this.maxPreview > 0) {
        return this.maxPreview
      }
      if (this.$tailwind.breakpoints.lg) {
        return 9
      }
      if (this.$tailwind.breakpoints.md) {
        return 6
      }
      if (this.$tailwind.breakpoints.sm) {
        return 8
      }
      return 4
    },
    range () {
      let start = this.iIndex - Math.ceil((this.iMaxPreview - 1) / 2)
      let end = this.iIndex + Math.floor((this.iMaxPreview - 1) / 2)

      if (start < 0) {
        end = end - start
      }

      if (end >= this.files.length) {
        start = start - (end - this.files.length) - 1
      }

      start = Math.max(start, 0)
      end = Math.min(end, this.files.length - 1)

      const range = []
      for (let i = start; i <= end; i++) {
        range.push(i)
      }

      return range
    }
  },
  methods: {
    openNested () {
      if (!this.fullScreen) {
        this.nested = true
      }
    },
    closeIfClickedOutside (target) {
      if (this.fullScreen && this.transparent) {
        const content = [this.$refs.file, this.$refs.gallery, this.$refs.closeIcon]
        for (const el of content) {
          // If any content was clicked, don't close. Also, if element is null, modal is already closed. In that case also return.
          if (!el || el.contains(target)) {
            return
          }
        }

        // If no content clicked, close.
        this.$emit('update:modelValue', false)
      }
    }
  },
  created () {
    this.iIndex = this.index
    if (this.$route.hash === '#' + this.$t('gallery')) {
      this.$emit('update:modelValue', true)
      document.body.style.overflowY = 'hidden'
    }
  }
}
</script>

<i18n>
{
  "en": {
    "gallery": "gallery"
  },
  "nl": {
    "gallery": "galerij"
  },
  "fr": {
    "gallery": "galerie"
  }
}
</i18n>
