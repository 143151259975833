<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta name="description" :content="$t('head.meta.description')"/>
    <meta name="keywords" :content="$t('head.meta.keywords')"/>
    <meta property="og:title" :content="$t('head.title')">
    <meta property="og:description" :content="$t('head.meta.description')">
  </Head>

  <BlackWhiteTemplate :title="$t('physiotherapy')">
    <div class="py-8" :class="{ 'container': $tailwind.breakpoints.lg }">
      <div class="grid grid-cols-1 lg:grid-cols-3 items-start gap-x-10 gap-y-8">
        <div class="lg:col-span-2" :class="{ 'container': !$tailwind.breakpoints.lg }">
          <i18n-t keypath="intro" tag="p">
            <template v-slot:introServices>
              <span class="text-red font-semibold">{{ $t('introServices') }}</span>
            </template>
          </i18n-t>
        </div>

        <div class="hidden lg:block lg:row-span-3 self-stretch">
          <Rates class="sticky top-8"></Rates>
        </div>

        <div class="lg:col-span-2 lg:-ml-6">
          <div class="bg-gray-100 md:shadow-md lg:rounded-xl border-b-4 border-beige-light xl:w-max">
            <div class="py-8 lg:py-6" :class="{ 'container': $tailwind.breakpoints.md && !$tailwind.breakpoints.lg }">
              <div class="px-4 md:px-0 lg:px-6">
                <h3 class="text-red">{{ $t('hydrotherapyTitle') }}</h3>
                <p>{{ $t('hydrotherapy1') }}</p>
                <p>{{ $t('hydrotherapy2') }}</p>
              </div>

              <div class="my-4">
                <video playsinline controls autoplay loop muted class="object-cover w-full h-96">
                  <source :src="require('@/assets/dog-hydro-14.mp4')" type="video/mp4">
                </video>
              </div>

              <div class="px-4 md:px-0 lg:px-6">
                <h4 class="text-gray-500">{{ $t('forTitle') }}</h4>
                <p>{{ $t('hydrotherapyFor') }}</p>
                <localized-link :to="{ path: '/hydrotherapie' }">
                  <Button>
                    {{ $t('hydrotherapyMore') }}
                    <template v-slot:iconRight>
                      <ChevronRightIcon/>
                    </template>
                  </Button>
                </localized-link>
              </div>
            </div>
          </div>
        </div>

        <div class="lg:col-span-2" :class="{ 'container': !$tailwind.breakpoints.lg }">
          <h3 class="text-red">{{ $t('manualTitle') }}</h3>
          <p>{{ $t('manual1') }}</p>
          <p>{{ $t('manual2') }}</p>
          <p>{{ $t('manual3') }}</p>
        </div>
      </div>

      <a id="laser" v-if="$tailwind.breakpoints.lg"></a>
    </div>

    <a id="laser" v-if="!$tailwind.breakpoints.lg"></a>
    <div class="w-100 overflow-x-hidden">
      <div class="container-right-text-aligned bg-gray-100 md:shadow-md rounded-l-xl border-b-4 border-beige-light md:flex items-center" data-aos="fade-left" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
        <div>
          <div class="px-4 md:pr-6 lg:pl-0 pt-8 lg:pt-6 mb-4">
            <h3 class="text-red">{{ $t('laserTitle') }}</h3>
            <p>{{ $t('laser') }}</p>
          </div>

          <img :src="require('@/assets/dog-laser-3.jpg')" class="md:hidden mb-4 h-132 w-full object-cover" :alt="$t('altDogLaserTherapy')">

          <div class="px-4 md:pr-6 lg:pl-0 pb-8 lg:pb-6">
            <h4 class="text-gray-500">{{ $t('forTitle') }}</h4>
            <p>{{ $t('laserFor') }}</p>
          </div>
        </div>

        <img :src="require('@/assets/dog-laser-3.jpg')" class="hidden md:block flex-grow object-cover md:w-2/5 lg:w-auto md:h-132 lg:h-104 xl:h-88" :alt="$t('altDogLaserTherapy')">
      </div>
    </div>

    <div class="container py-8">
      <h3 class="text-red">{{ $t('exerciseTitle') }}</h3>
      <p>{{ $t('exercise') }}</p>
      <FadingImages :imgs="[require('@/assets/dog-fitness-2.jpg'), require('@/assets/dog-fitness-19a.jpg'), require('@/assets/dog-fitness-20a.jpg')]" :alt="$t('altDogExercises')" class="w-full max-w-text h-96 md:h-132 rounded-lg"></FadingImages>
    </div>

    <div class="container lg:hidden pb-8">
      <Rates></Rates>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import FadingImages from '@/components/FadingImages'
import Button from '@/components/Button'

// Define rates in separate component as will need to be used twice in different places depending on screen size.
const Rates = {
  template: `
    <div class="p-4 shadow-md bg-gray-100 rounded-lg">
      <h3 class="text-red">{{ $tc('rate', 2) }}</h3>
      <h4 class="italic mb-2">{{ $t('rateATitle') }}</h4>
      <p class="text-left text-gray-500">{{ $t('rateA1') }}</p>
      <h4 class="italic mb-2">{{ $t('rateBTitle') }}</h4>
      <p class="text-left text-gray-500">{{ $t('rateB1') }}</p>
      <h4 class="italic mb-2">{{ $t('rateCTitle') }}</h4>
      <p class="text-left text-base text-gray-500 mb-2">{{ $t('rateCNote') }}</p>
      <p class="text-left text-gray-500">
        {{ $t('rateC1') }}<br>
      </p>
      <h4 class="italic mb-0">{{ $t('rateDTitle') }}</h4>
      <p class="text-left text-base text-gray-500 mb-2">{{ $t('rateDNote') }}</p>
      <p class="text-left text-gray-500">
        {{ $t('rateD1') }}
      </p>
    </div>
  `,
  i18n: {
    messages: {
      nl: {
        "rateATitle": "Fysiotherapie",
        "rateA1": "Per sessie (30 min): €\xa035",
        "rateBTitle": "Hydrotherapie",
        "rateB1": "Per sessie (30 min): €\xa040",
        "rateCTitle": "Combo fysio + hydro",
        "rateCNote": "Hydrotherapie in combinatie met laser-, oefen- en/of manuele therapie.",
        "rateC1": "Per sessie (45 min): €\xa045",
        "rateDTitle": "Eerste sessie",
        "rateDNote": "Tijdens de eerste consultatie nemen we voldoende tijd om de behandeling te bespreken.",
        "rateD1": "Eerste sessie (45-60 min): €\xa050"
      }
    }
  }
}

export default {
  name: 'Physiotherapy',
  inject: ['$aosRefresh'],
  components: {
    Head,
    ChevronRightIcon,
    BlackWhiteTemplate,
    FadingImages,
    Button,
    Rates
  },
  updated() {
    this.$aosRefresh()
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Fysiotherapie - Yn'forma",
      "meta": {
        "description": "Fysiotherapie en revalidatie voor huisdieren door middel van hydrotherapie, oefeningen, lasertherapie, massages en fitness. Gevestigd te Gooik.",
        "keywords": "revalidatie, fysiotherapie, hydrotherapie, lasertherapie, huisdieren, gooik, oetingen, hondenfitness, dierenfitness, fitness, massage, yn'forma, ynforma, honden, hond, katten, kat, huisdier"
      }
    },
    "physiotherapy": "Fysiotherapie",
    "intro": "In samenspraak met uw dierenarts of fysiotherapeut kan uw hond hier onder begeleiding beginnen aan zijn fysiotherapie en revalidatie. Dit kan met behulp van de {introServices}.",
    "introServices": "onderwaterloopband, manuele therapie, lasertherapie en oefentherapie",
    "hydrotherapyTitle": "Hydrotherapie",
    "hydrotherapy1": "Hydrotherapie is een vorm van therapie waarbij uw huisdier zich onbelast voortbeweegt in water. Dit kan bij ons op een speciaal hiervoor ontwikkelde onderwaterloopband.",
    "hydrotherapy2": "Dankzij de opwaartse kracht van het water worden de spieren getraind zonder de gewrichten te belasten. De weerstand van het water zorgt voor een verbeterde spieropbouw en uithouding. Het primaire doel van de onderwaterloopband is dus de spiermassa op te bouwen en de spiertonus te verbeteren, wat leidt tot een betere stabiliteit van de gewrichten. Zo is er minder pijn en een betere functionaliteit. Het water heeft een temperatuur van ongeveer 28 graden, wat de bloedcirculatie stimuleert en soepele gewrichten oplevert.",
    "forTitle": "Wanneer?",
    "hydrotherapyFor": "Na orthopedische of neurologische chirurgie en bij artrose of overgewicht.",
    "hydrotherapyMore": "Meer over hydrotherapie",
    "manualTitle": "Manuele therapie: massage, stretching en mobilisaties",
    "manual1": "Met massage verbeteren we de bloed- en lymfecirculatie, stimuleren we de huid, beïnvloeden we het zenuwstelsel en zorgen we voor een snellere afvoer van afvalstoffen. Het heeft een ontspannend effect op de spieren, waardoor ze soepel en fit blijven en de gewrichten beter ondersteund worden. De betere doorbloeding zorgt tevens voor pijnreductie. Ook heeft massage een psychologisch effect: uw huisdier wordt er ontspannen van.",
    "manual2": "Met stretching verbeteren we de flexibiliteit en de uitbreidbaarheid van de spieren, pezen, ligamenten en andere structuren. De mobiliteit van de hond zal hierdoor verbeteren.",
    "manual3": "Met mobilisaties bevorderen we de bewegelijkheid van de gewrichten en zorgen we voor een pijnstillend effect.",
    "laserTitle": "Lasertherapie",
    "laser": "Lasertherapie is een pijnloze behandeling waarbij licht gebruikt wordt om het zelfherstellend vermogen van de huid en onderliggende weefsels te activeren. Het stimuleert de doorbloeding en de heling, en vermindert zowel acute als chronische pijn. Lasertherapie heeft geen bijwerkingen. Het is effectief tegen pijn waardoor uw huisdier zich makkelijk kan bewegen en zich beter voelt.",
    "laserFor": "Na orthopedische of neurologische chirurgie, bij artrose of overgewicht, pijnlijke gewrichten, rug- en nekpijn, pees- en ligamentenletsels, spierblessures, wondbehandeling\xa0... ",
    "exerciseTitle": "Oefentherapie",
    "exercise": "Als uw huisdier er klaar voor is, kunnen we verschillende oefeningen aanleren en uitvoeren zodat de stabiliteit, evenwicht, balans, steunname, spierkracht en uithouding terug op peil komt te staan. U krijgt ook een oefenprogramma mee naar huis zodat we een optimaal resultaat kunnen behalen."
  }
}
</i18n>
