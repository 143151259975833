<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta name="description" :content="$t('head.meta.description')"/>
    <meta name="keywords" :content="$t('head.meta.keywords')"/>

    <meta property="og:type" content="website">
    <meta property="og:site_name" content="Yn'forma">
    <meta property="og:url" :content="url">
    <meta property="og:title" :content="$t('head.title')">
    <meta property="og:description" :content="$t('head.meta.description')">
    <meta property="og:image" content="https://ynforma.be/animals.jpg">
  </Head>

  <!-- TODO: images: add multiple dimensions. Real images.
  Also, both logo and images need to be crawlable and indexable by Google. -->
  <teleport to="head">
    <component :is="'script'" type="application/ld+json" id="ld" v-if="!ldScriptLoaded">
      {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Yn'forma",
        "description": "{{ $t('head.ld.description') }}",
        "url": "{{ $t('head.ld.url') }}",
        "image": "https://ynforma.be/logo.svg",
        "logo": "https://ynforma.be/logo.svg",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Kerkhofstraat 10",
          "addressLocality": "Gooik",
          "addressRegion": "Oost-Vlaanderen",
          "postalCode": "1755",
          "addressCountry": "BE"
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": 50.768470,
          "longitude": 4.066982
        },
        "hasMap": "https://maps.app.goo.gl/V6e7FsxBSA7WjJT16",
        "email": "mailto:yne@ynforma.be",
        "telephone": "+32471108278",
        "priceRange": "$",
        "openingHoursSpecification": [
          {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday"
            ],
            "opens": "08:30",
            "closes": "19:00"
          }
        ]
      }
    </component>
  </teleport>

  <div class="min-h-screen flex flex-col text-lg">
    <!-- Overlay menu on smaller than md. -->
    <div class="flex flex-col p-4 text-center text-beige-light items-center fixed inset-0 bg-black z-50 transition-opacity duration-300 font-hand text-3xl overflow-y-auto" :class="{ 'opacity-0 pointer-events-none': $route.hash !== '#' + $t('menu') }">
      <div class="container flex items-center px-0 space-x-4 text-beige-light">
        <localized-link :to="{ path: '/' }" replace><img :src="require('@/assets/logo.svg')" class="h-24" alt=""></localized-link>
        <div class="flex-grow"></div>

        <button @click="toggleMenu" class="fixed right-4">
          <XIcon class="h-5"/>
        </button>
      </div>

      <div class="flex-grow"></div>

      <div class="flex flex-col space-y-12 mt-8 mb-4 items-center">
        <localized-link :to="{ path: '/over' }" replace>{{ $t('aboutYnforma') }}</localized-link>
        <div class="relative">
          <div class="flex">
            <localized-link :to="{ path: '/diensten' }" replace>{{ $t('services') }}</localized-link>
            <button class="ml-3">
              <ChevronUpIcon class="h-0 w-5 transition-height duration-500" :class="{ 'h-5': showServices }" @click="showServices = false"/>
              <ChevronDownIcon class="h-0 w-5 transition-height duration-500" :class="{ 'h-5': !showServices }" @click="showServices = true"/>
            </button>
          </div>
          <div class="h-0 font-sans text-lg flex flex-col transition-height duration-500 overflow-y-hidden" :class="{ 'h-60': showServices }"> <!-- TODO: replace fixed height by JS calculation. -->
            <localized-link :to="{ path: '/hydrotherapie' }" replace class="mt-4">{{ $t('hydrotherapy') }}</localized-link>
            <localized-link :to="{ path: '/fysiotherapie' }" replace class="mt-4">{{ $t('physiotherapy') }}</localized-link>
            <localized-link :to="{ path: '/hondenfitness' }" replace class="mt-4">{{ $t('dogFitness') }}</localized-link>
            <localized-link :to="{ path: '/acupunctuur' }" replace class="mt-4">{{ $t('acupuncture') }}</localized-link>
            <localized-link :to="{ path: '/trimsalon' }" replace class="mt-4">{{ $t('groomingSalon') }}</localized-link>
          </div>
        </div>
        <localized-link :to="{ path: '/winkel' }" replace>{{ $t('shop') }}</localized-link>
        <localized-link :to="{ path: '/contact' }" replace class="underline underline-red decoration-3">{{ $t('contact') }}</localized-link>
      </div>

      <div class="flex-grow"></div>
    </div>

    <div class="bg-black flex flex-col flex-grow">
      <!-- Header and navigation. -->
      <div class="container flex items-center space-x-12 lg:space-x-16 py-4 text-beige-light font-hand text-3xl">
        <localized-link :to="{ path: '/' }">
          <img :src="require('@/assets/logo.svg')" class="h-24" alt="">
        </localized-link>

        <div class="flex-grow"></div>

        <!-- Hamburger menu on smaller than md. -->
        <button @click="toggleMenu" class="fixed right-4 md:hidden z-40 mix-blend-difference">
          <MenuIcon class="h-5"/>
        </button>

        <!-- Menu links on md and larger. -->
        <localized-link :to="{ path: '/over' }" class="hidden md:block">{{ $tailwind.breakpoints.lg ? $t('aboutYnforma') : $t('about') }}</localized-link>
        <div class="hidden md:block relative" @mouseenter="showServices = true" @mouseleave="showServices = false">
          <div class="flex">
            <localized-link :to="{ path: '/diensten' }" @click="showServices = false">{{ $t('services') }}</localized-link>
            <button class="ml-3">
              <ChevronUpIcon class="h-0 w-5 transition-height duration-500" :class="{ 'h-5': showServices }" @click="showServices = false"/>
              <ChevronDownIcon class="h-0 w-5 transition-height duration-500" :class="{ 'h-5': !showServices }" @click="showServices = true"/>
            </button>
          </div>
          <div class="h-0 z-40 px-4 -ml-4 font-sans text-lg rounded-md bg-black bg-opacity-70 min-w-full absolute flex flex-col top-8 transition-height duration-500 overflow-y-hidden overflow-x-visible" :class="{ 'h-52': showServices }"> <!-- TODO: replace fixed height by JS calculation. -->
            <localized-link :to="{ path: '/hydrotherapie' }" @click="showServices = false" class="mt-3">{{ $t('hydrotherapy') }}</localized-link>
            <localized-link :to="{ path: '/fysiotherapie' }" @click="showServices = false" class="mt-3">{{ $t('physiotherapy') }}</localized-link>
            <localized-link :to="{ path: '/hondenfitness' }" @click="showServices = false" class="mt-3">{{ $t('dogFitness') }}</localized-link>            
            <localized-link :to="{ path: '/acupunctuur' }" @click="showServices = false" class="mt-3">{{ $t('acupuncture') }}</localized-link>
            <localized-link :to="{ path: '/trimsalon' }" @click="showServices = false" class="mt-3">{{ $t('groomingSalon') }}</localized-link>
          </div>
        </div>
        <localized-link :to="{ path: '/winkel' }" class="hidden md:block">{{ $t('shop') }}</localized-link>
        <localized-link :to="{ path: '/contact' }" class="hidden md:block underline underline-red decoration-3">{{ $t('contact') }}</localized-link>
      </div>

      <router-view/>
    </div>

    <div class="bg-beige-light">
      <div class="container pt-8 pb-4 text-center text-beige-dark">
        <h2 class="font-hand text-red underline decoration-3">{{ $t('openingHoursHeader') }}</h2>
        <localized-link :to="{ path: '/contact' }">{{ $t('openingHours') }}</localized-link>

        <h2 class="font-hand text-red underline decoration-3">
          <localized-link :to="{ path: '/contact' }">{{ $t('contact') }}</localized-link>
        </h2>

        <a href="mailto:yne@ynforma.be">yne@ynforma.be</a><br>
        <a href="tel:+32-471-108-278">0471/108.278</a><br><br>
        <a href="https://maps.app.goo.gl/V6e7FsxBSA7WjJT16" target="_blank" rel="noreferrer">
          Kerkhofstraat 10<br>
          1755 Gooik
        </a>

        <br><br>

        <a href="http://facebook.com/ynforma" target="_blank" rel="noreferrer"><img :src="require('@/assets/facebook-logo.svg')" class="inline-block h-8 mr-4 filter-red" :alt="$t('altFacebookIcon')"></a>
        <a href="http://instagram.com/ynforma" target="_blank" rel="noreferrer"><img :src="require('@/assets/instagram-logo.svg')" class="inline-block h-8 filter-red" :alt="$t('altInstagramIcon')"></a>

        <br><br>
        
        <span class="text-base md:text-lg">
          <localized-link :to="{ path: '/privacyverklaring' }">{{ $t('privacyPolicy') }}</localized-link> &bull; <localized-link :to="{ path: '/verzend-en-retourbeleid' }">{{ $t('shippingReturnPolicy') }}</localized-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { Head } from '@vueuse/head'
import { MenuIcon, XIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/vue/solid'

export default {
  name: 'Info',
  components: {
    Head,
    MenuIcon,
    XIcon,
    ChevronUpIcon,
    ChevronDownIcon
  },
  data () {
    return {
      ldScriptLoaded: true,
      showServices: false
    }
  },
  watch: {
    $route (val) {
      if (val.hash === '#' + this.$t('menu')) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'auto'
      }
    }
  },
  computed: {
    url () {
      return `https://ynforma.be${this.$route.fullPath}`
    }
  },
  methods: {
    toggleMenu () {
      if (this.$route.hash === '#' + this.$t('menu')) {
        if (history.state.back) {
          this.$router.go(-1)
        } else {
          this.$router.replace({
            ...this.$route,
            hash: null
          })
        }
      } else {
        this.$router.push({
          ...this.$route,
          hash: '#' + this.$t('menu')
        })
      }
    }
  },
  created () {
    // Due to prerendering, ld script is normally loaded twice: once during prerendering and once during hydration. Below code prevents this.
    if (!document.getElementById('ld')) {
      this.ldScriptLoaded = false
    }
  }
}
</script>

<style>
.filter-red {
  filter: brightness(0) saturate(100%) invert(13%) sepia(95%) saturate(2699%) hue-rotate(15deg) brightness(95%) contrast(102%);
}

.filter-black {
 filter: brightness(0);
}
</style>

<i18n>
{
  "nl": {
    "head": {
      "title": "Yn'forma - Trimsalon, hydrotherapie, revalidatie en meer",
      "meta": {
        "description": "Yn'forma: zorg voor uw hond en andere huisdieren. Gevestigd te Gooik. Trimsalon, hydrotherapie, revalidatie, lasertherapie, hondenfitness en meer.",
        "keywords": "trimsalon, hydrotherapie, fysiotherapie, gooik, oetingen, yn'forma, ynforma, revalidatie, trimmen, trim, honden, hond, kat, katten, huisdier, huisdieren"
      },
      "ld": {
        "description": "Yn'forma: zorg en begeleiding voor uw hond en andere huisdieren. Gevestigd te Gooik. Trimsalon, hydrotherapie, revalidatie, lasertherapie, hondenfitness, acupunctuur en voedingsadvies.",
        "url": "https://ynforma.be"
      }
    },
    "menu": "menu",
    "about": "Over",
    "aboutYnforma": "Over Yn'forma",
    "services": "Diensten",
    "shop": "Winkel",
    "contact": "Contact",
    "openingHoursHeader": "Openingsuren",
    "openingHours": "Open op afspraak",
    "hydrotherapy": "Hydrotherapie",
    "physiotherapy": "Fysiotherapie",
    "groomingSalon": "Trimsalon",
    "laserTherapy": "Lasertherapie",
    "dogFitness": "Hondenfitness",
    "puppyTraining": "Puppytraining",
    "nutrition": "Voedingsadvies",
    "acupuncture": "Acupunctuur",
    "privacyPolicy": "Privacyverklaring",
    "shippingReturnPolicy": "Verzend- en retourbeleid"
  }
}
</i18n>
