<template>
  <div>
    <VField :name="name" v-slot="{ field, resetField, errorMessage }" :validateOnBlur="false" :validateOnChange="true" :validateOnInput="false">
      <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 -mt-5">
        <div class="text-base top-3 left-2 relative">
          <label :for="name" class="bg-white px-1" :class="{ 'invisible': !field.value, 'text-red': errorMessage, 'bg-gray-200': disabled }">{{ label }}</label>
        </div>
        <div class="col-start-1 flex">
          <input :disabled="disabled" :type="type" :placeholder="label" :id="name" :autocomplete="autocomplete" v-bind="field" @change="removeEmpty(resetField, $event)" class="w-full rounded-lg py-2 px-3 outline-none ring-1 ring-black" :class="{ 'text-red ring-2 ring-red': errorMessage, 'bg-gray-200': disabled }"/>
          <slot name="right"></slot>
        </div>
        <span class="text-red flex items-center mt-1 lg:mt-0 lg:ml-3 xl:col-span-2" v-show="errorMessage">
          <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
          <span>{{ errorMessage }}</span>
        </span>
      </div>
    </VField>
  </div>
</template>

<script>
import { Field as VField } from 'vee-validate'
import { ExclamationIcon } from '@heroicons/vue/solid'

export default {
  name: 'Field',
  components: {
    VField,
    ExclamationIcon
  },
  props: {
    name: String,
    label: String,
    type: String,
    default: Object,
    autocomplete: String,
    disabled: {
      type: Boolean,
      value: false
    }
  },
  methods: {
    removeEmpty (reset, event) {
      if (typeof event.target.value === 'string' && event.target.value.trim() === '') {
        // If no default is provided, default is undefined, which is precisely what we would want: this input's key will be removed from the object.
        // If a value is provided, then that value is set.
        reset({ value: this.default })
      }
    }
  }
}
</script>
