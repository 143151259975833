<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
  </Head>

  <BlackWhiteTemplate :title="$t('register')" title-size="sm">
    <div class="container py-8">
      <p>{{ $t('registerIntro') }}</p>

      <Form @submit="register" :validation-schema="registerSchema" class="mb-4">
        <Field name="firstName" type="text" :label="$t('firstName')" autocomplete="given-name" class="mb-4"/>
        <Field name="lastName" type="text" :label="$t('lastName')" autocomplete="family-name" class="mb-4"/>
        <Field name="email" type="text" :label="$t('email')" autocomplete="email" class="mb-4"/>
        <Field name="phoneNumber" type="text" :label="$t('phoneNumber')" autocomplete="tel" class="mb-4"/>
        <Field name="password" type="password" :label="$t('password')" autocomplete="new-password" class="mb-4"/>
        <Field name="repeatPassword" type="password" :label="$t('repeatPassword')" autocomplete="new-password" class="mb-4"/>

        <div v-show="errorMessage" class="mb-4 text-red flex items-start">
          <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
          <span>{{ errorMessage }}</span>
        </div>

        <Button type="submit">{{ $t('register') }}</Button>
      </Form>

      <p class="text-left">
        {{ $t('alreadyAccount') }}
        <localized-link :to="{ path: '/inloggen' }" class="text-red">{{ $t('logIn') }}</localized-link>
      </p>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import Button from '@/components/Button'
import { Form } from 'vee-validate'
import '@/validations'
import * as yup from 'yup'
import Field from '@/components/Field'
import { ExclamationIcon } from '@heroicons/vue/solid'

export default {
  name: 'Register',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    Head,
    BlackWhiteTemplate,
    Button,
    Field,
    Form,
    ExclamationIcon
  },
  data () {
    return {
      registerSchema: yup.object({
        email: yup.string()
          .required(this.$t('validation.email.required'))
          .email(this.$t('validation.email.email')),
        password: yup.string()
          .required(this.$t('validation.password.required'))
          .password(this.$tc('validation.password.min', 8),
            this.$tc('validation.password.number', 1),
            this.$tc('validation.password.letter', 1)),
        repeatPassword: yup.string()
          .required(this.$t('validation.repeatPassword.required'))
          .oneOf([yup.ref('password')], this.$t('validation.repeatPassword.matchPassword')),
        firstName: yup.string()
          .required(this.$t('validation.firstName.required')),
        lastName: yup.string()
          .required(this.$t('validation.lastName.required')),
        phoneNumber: yup.string()
          .required(this.$t('validation.phoneNumber.required'))
          .phoneNumber(this.$t('validation.phoneNumber.format'))
      }),
      errorMessage: null
    }
  },
  methods: {
    register (values, actions) {
      this.$axios.post('/auth/register', {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber
      })
        .then(res => {
          this.$store.commit('setCurrentUser', res.data.user)
          if (res.data.user.role === 'admin') {
            this.$router.push({ path: '/portaal' })
          } else {
            this.$router.push({ path: '/klantenportaal' })
          }
        })
        .catch(err => {
          this.errorMessage = this.$axiosErrorHandler(err, values, actions)
        })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "head": {
      "title": "Register - Yn'forma",
    },
    "register": "Register",
    "registerIntro": "Create an account to make appointments faster, to view your appointments and to consult the information we have about your pets.",
    "alreadyAccount": "Do you already have an account?",
    "logIn": "Log in!"
  },
  "nl": {
    "head": {
      "title": "Account aanmaken - Yn'forma",
    },
    "register": "Account aanmaken",
    "registerIntro": "Maak een account aan om gemakkelijker afspraken te maken, om uw afspraken te bekijken en om de gegevens die we over uw huisdieren hebben te raadplegen.",
    "alreadyAccount": "Heeft u al een account?",
    "logIn": "Inloggen!"
  },
  "fr": {
    "head": {
      "title": "Création de compte - Yn'forma",
    },
    "register": "Créer votre compte",
    "registerIntro": "Créez un compte pour faire des rendez-vous plus rapidement, pour voir vos rendez-vous et pour consulter les données que nous avons sur vos animaux.",
    "alreadyAccount": "Avez-vous déjà un compte ?",
    "logIn": "Connectez-vous!"
  }
}
</i18n>
