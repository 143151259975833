<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta name="description" :content="$t('head.meta.description')"/>
    <meta name="keywords" :content="$t('head.meta.keywords')"/>
    <meta property="og:title" :content="$t('head.title')">
    <meta property="og:description" :content="$t('head.meta.description')">
  </Head>

  <BlackWhiteTemplate :title="$t('groomingSalon')">
    <div class="container py-8">
      <div class="grid grid-cols-1 lg:grid-cols-3 items-start gap-x-10 gap-y-4 lg:gap-y-8">
        <div class="lg:col-span-2">
          <p>{{ $t('content1') }}</p>
          <p>{{ $t('content2') }}</p>
          <FadingImages :imgs="groomingImgs" :alt="$t('altDogGrooming')" class="rounded-lg h-80 md:h-120 w-full max-w-text lg:mb-0"></FadingImages>
        </div>

        <div class="lg:bg-gray-100 lg:shadow-md lg:rounded-xl lg:border-b-4 lg:border-beige-light">
          <div class="lg:pt-6 lg:px-6 mb-4">
            <h3 class="text-red">{{ $t('puppyTrainingTitle') }}</h3>
            <p>{{ $t('puppyTraining') }}</p>
          </div>

          <FadingImages :imgs="[require('@/assets/puppy-training-6.jpg'), require('@/assets/puppy-training-5.jpg'), require('@/assets/puppy-training-8.jpg')]" :delay="2000" :alt="$t('altPuppyTrainingHabit')" class="md:hidden lg:block rounded-lg lg:rounded-t-none w-full h-96 relative top-24 lg:top-0 -mt-24 lg:mt-0 mb-4 lg:mb-0"></FadingImages>

          <div class="hidden md:flex lg:hidden gap-4 mb-4 relative top-24 -mt-24 h-80">
            <div class="flex-1">
              <img :src="require('@/assets/puppy-training-6.jpg')" class="object-cover w-full h-full rounded-lg" :alt="$t('altPuppyTrainingHabit')">
            </div>
            <div class="flex-1">
              <img :src="require('@/assets/puppy-training-5.jpg')" class="object-cover w-full h-full rounded-lg" :alt="$t('altPuppyTrainingHabit')">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-gradient-to-b from-gray-100 to-white">
      <div class="container pt-20 lg:pt-8 pb-8">
        <div class="flex flex-col items-center">
          <h2 class="font-hand underline decoration-3 text-red text-center">{{ $t('prices') }}</h2>
          <div class="mb-4">
            <i18n-t keypath="basePrice" tag="p">
              <template v-slot:hourlyRateSmall>
                <span class="font-medium">{{ $t('hourlyRateSmall') }}</span>
              </template>
              <template v-slot:hourlyRateLarge>
                <span class="font-medium">{{ $t('hourlyRateLarge') }}</span>
              </template>
            </i18n-t>
            <p>{{ $t('pricesDepend') }}</p>
            <p>{{ $t('noNew') }}</p>
          </div>

          <table class="w-full lg:w-120 mx-auto unformatted">
            <template v-for="(breed, breedIndex) in prices" :key="breedIndex">
              <tr v-if="!breed.variants">
                <td class="align-top py-1">{{ $t(`breeds.${breed.breed}`) }}</td>
                <td class="align-top py-1">{{ variantAction(breed) }}</td>
                <td class="align-top py-1">{{ breed.price }}</td>
              </tr>
              <template v-else>
                <tr v-for="(variant, variantIndex) in breed.variants" :key="variantIndex">
                  <td class="align-top py-1">{{ variantIndex === 0 ? $t(`breeds.${breed.breed}`) : '' }}</td>
                  <td class="align-top py-1">{{ variantAction(variant) }}</td>
                  <td class="align-top py-1">{{ variant.price }}</td>
                </tr>
              </template>
            </template>
          </table>
        </div>
      </div>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import FadingImages from '@/components/FadingImages'

export default {
  name: 'GroomingSalon',
  components: {
    Head,
    BlackWhiteTemplate,
    FadingImages
  },
  data () {
    return {
      prices: [{
        breed: 'australianShepherd',
        price: 85,
      }, {
        breed: 'bichonFrise',
        price: 70
      }, {
        breed: 'borderCollie',
        price: 75
      }, {
        breed: 'chihuahua',
        price: 45
      }, {
        breed: 'englishCockerSpaniel',
        price: 80
      }, {
        breed: 'goldenRetriever',
        price: 85
      }, {
        breed: 'havanese',
        price: 75
      }, {
        breed: 'keeshond',
        variants: [{
          variant: 'small',
          price: 70
        }]
      }, {
        breed: 'labrador',
        price: 55
      }, {
        breed: 'maltese',
        price: 70
      }, {
        breed: 'poodle',
        variants: [{
          variant: 'miniature',
          price: 70
        }]
      }, {
        breed: 'sheltie',
        price: 75
      }, {
        breed: 'shihTzu',
        price: 70
      }, {
        breed: 'dachshund',
        variants: [{
          variant: 'wire',
          price: 65
        }]
      }, {
        breed: 'yorkshire',
        price: 70
      }, {
        breed: 'cavalier',
        price: 75
      }].sort((a, b) => {
        const breedA = this.$t(`breeds.${a.breed}`).toLowerCase()
        const breedB = this.$t(`breeds.${b.breed}`).toLowerCase()
        if (breedA < breedB) {
          return -1
        }
        if (breedA > breedB) {
          return 1
        }
        return 0
      })
    }
  },
  computed: {
    groomingImgs () {
      const imgs = [require('@/assets/dog-trimming-25.jpg'), require('@/assets/dog-trimming-4a.jpg'), require('@/assets/dog-trimming-31.jpg'), require('@/assets/dog-trimming-18.jpg'), require('@/assets/dog-trimming-5.jpg')]

      // On xs screens, add a beautiful picture that has a format that doesn't fit nicely on larger screens.
      if (!this.$tailwind.breakpoints.sm) {
        imgs.splice(2, 0, require('@/assets/dog-trimming-32.jpg'))
      }

      return imgs
    }
  },
  methods: {
    variantAction (breedOrVariant) {
      let result = ''
      if (breedOrVariant.variant) {
        result = this.$t(`variants.${breedOrVariant.variant}`)
        if (breedOrVariant.action) {
          result += ` (${this.$t(`actions.${breedOrVariant.action}`)})`
        }
      } else if (breedOrVariant.action) {
        result = this.$t(`actions.${breedOrVariant.action}`)
      }

      if (result.length > 0) {
        return result[0].toUpperCase() + result.substring(1)
      }
      return result
    }
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Trimsalon - Yn'forma",
      "meta": {
        "description": "Hondentrimsalon in Gooik. Plukken, knippen, scheren, ontwollen, ontklitten en wassen van uw viervoeter.",
        "keywords": "trimsalon, gooik, oetingen, toilettage, hondentoilettage, yn'forma, ynforma, trimmen, trim, honden, hond, huisdier, huisdieren"
      }
    },
    "groomingSalon": "Trimsalon",
    "content1": "Elke hond heeft recht op een gepaste behandeling voor zijn vacht. Wij houden steeds rekening met de rasstaandaard van de hond en met de wensen van de eigenaar. We stellen een positieve ervaring voor het dier voorop. Een standaard trimbeurt omvat het knippen van de nagels, kuisen van de oren, borstelen, wassen, drogen en het trimmen van de hond. We kunnen uw hond plukken, knippen, scheren, ontwollen of ontklitten.",
    "content2": "De duur en de prijs van elke trimbeurt hangen af van het ras, de conditie van de vacht, de frequentie van het aantal bezoeken en het gedrag van de hond.",
    "puppyTrainingTitle": "Gratis puppygewenning!",
    "puppyTraining": "We bieden een gratis puppygewenning aan voor puppy’s tot en met 5 maanden. Puppy’s worden uitgenodigd om op jonge leeftijd tijdens een korte sessie te leren dat wassen, drogen, borstelen en knippen fijn kan zijn. Samen met het baasje zorgen we voor een leuke eerste ervaring in het salon.",
    "prices": "Tarieven",
    "basePrice": "Ons tarief is {hourlyRateSmall} voor kleine rassen en {hourlyRateLarge} voor grote rassen.",
    "hourlyRateSmall": "€\xa050 per uur",
    "hourlyRateLarge": "€\xa055 per uur",
    "pricesDepend": "Hieronder vindt u een schatting van de kostprijs voor veelvoorkomende rassen, gebaseerd op ons uurtarief. Hou er rekening mee dat uiteindelijke prijs kan afwijken naargelang de toestand van de vacht, de frequentie van het aantal bezoeken en het gedrag van de hond.",
    "noNew": "Op dit ogenblik nemen wij geen nieuwe doodlevarianten en plukhonden meer aan.",
    "breeds": {
      "australianShepherd": "Australische herder",
      "bichonFrise": "Bichon frisé",
      "borderCollie": "Bordercollie",
      "chihuahua": "Chihuahua",
      "englishCockerSpaniel": "Engelse cockerspaniël",
      "goldenRetriever": "Golden retriever",
      "havanese": "Havanezer",
      "keeshond": "Keeshond",
      "labrador": "Labrador",
      "maltese": "Maltezer",
      "poodle": "Poedel",
      "sheltie": "Sheltie",
      "shihTzu": "Shih Tzu",
      "dachshund": "Teckel",
      "yorkshire": "Yorkshire",
      "cavalier": "Cavalier"
    },
    "variants": {
      "wire": "ruwhaar",
      "short": "korthaar",
      "miniature": "dwerg",
      "small": "klein"
    },
    "actions": {
      "pluck": "plukken"
    }
  }
}
</i18n>
