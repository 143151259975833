<template>
  <button :type="type" :class="{ 'bg-gray-300 hover:bg-gray-200 rounded-lg text-center py-2 px-3': !flat, 'hover:text-gray-500': flat }">
    <div class="icon icon-left inline">
      <slot name="iconLeft"></slot>
    </div>

    <div class="inline-block">
      <slot></slot>
    </div>

    <div class="icon icon-right inline">
      <slot name="iconRight"></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    flat: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="postcss">
.icon > * {
  @apply h-5 inline mb-0.5;
}

.icon-left > * {
  @apply mr-1;
}

.icon-right > * {
  @apply ml-1;
}
</style>
