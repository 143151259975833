<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta property="og:title" :content="$t('head.title')">
  </Head>

  <div class="container flex justify-center items-end">
    <div class="relative w-full top-10 md:max-w-xl p-4 md:p-6 shadow-md bg-beige-light rounded-lg">
      <h2 class="font-hand text-red underline decoration-3">{{ $t('aboutYnformaHeader') }}</h2>
      <p class="text-justify">{{ $t('aboutYnformaContent') }}</p>
    </div>
    <img :src="require('@/assets/cat-side-1.jpg')" class="hidden md:block h-72" alt="">
  </div>

  <div class="bg-white flex-grow pt-10">
    <div class="container py-8">
      <h2 class="font-hand text-red underline decoration-3 text-center">{{ $t('aboutMeHeader') }}</h2>
      <div class="md:flex justify-center">
        <FadingImages :imgs="[require('@/assets/dog-fitness-14-edited-1.jpg'), require('@/assets/yne-cheetah-1.jpg'), require('@/assets/yne-pool-1.jpg')]" :alt="[$t('altYneFitness'), $t('altYneCheetah'), $t('altYnePool')]" class="h-96 sm:h-120 md:h-auto mb-4 md:mb-0 md:w-96 md:order-2 rounded-xl"></FadingImages>
        <div class="md:order-1 md:mr-6">
          <div class="md:max-w-sm">
            <i18n-t keypath="aboutMeContent" tag="p" class="text-justify">
              <template v-slot:name>
                <span class="text-red font-semibold">Yne Pieters</span>
              </template>
            </i18n-t>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Head } from '@vueuse/head'
import FadingImages from '@/components/FadingImages'

export default {
  name: 'About',
  components: {
    Head,
    FadingImages
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Over Yn'forma - Yn'forma"
    },
    "aboutYnformaHeader": "Over Yn'forma",
    "aboutYnformaContent": "Yn’forma staat in voor de algemene gezondheid en het welzijn van de dieren. Een gezonde vacht, aangepaste voeding, fysieke fitheid en een positieve mens-dierrelatie zijn cruciaal voor een gelukkig dier. Yn’forma richt zich tot diereigenaars die de gezondheid van hun dier voorop willen zetten. Onze missie is dan ook om via professionele ondersteuning en advies het welzijn van het dier te optimaliseren. Medische problemen worden in overleg met een dierenarts of fysiotherapeut correct behandeld.",
    "aboutMeHeader": "Wie ben ik?",
    "aboutMeContent": "Ik ben {name}, een gepassioneerde dierenvriend met een hart voor revalidatie en fysiotherapie. Mijn diensten strekken zich uit van conventionele geneeskunde tot alternatieve methoden, waarbij ik de kracht van verschillende benaderingen combineer voor optimale resultaten. Met een focus op fysiotherapie, hydrotherapie, acupunctuur en binnenkort ook osteopathie (in opleiding) zet ik me in om de gezondheid en het welzijn van uw huisdieren te bevorderen. Samen streven we naar het herstel en de vitaliteit van uw geliefde viervoeter!",
  }
}
</i18n>
