<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
  </Head>

  <BlackWhiteTemplate :title="$t('logIn')">
    <div class="container py-8">
      <p>{{ $t('loginIntro') }}</p>

      <Form @submit="login" :validation-schema="loginSchema" class="mb-4">
        <Field name="email" type="text" :label="$t('email')" autocomplete="username" class="mb-4"/>
        <Field name="password" type="password" :label="$t('password')" autocomplete="current-password" class="mb-4"/>

        <div v-show="errorMessage" class="mb-4 text-red flex items-start">
          <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
          <span>{{ errorMessage }}</span>
        </div>

        <Button type="submit">{{ $t('logIn') }}</Button>
      </Form>

      <p class="text-left">
        <localized-link :to="{ path: '/wachtwoord-vergeten' }" class="text-red">{{ $t('forgotPassword') }}</localized-link>
        <br>
        {{ $t('noAccount') }}
        <localized-link :to="{ path: '/registreren' }" class="text-red">{{ $t('register') }}</localized-link>
      </p>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import Button from '@/components/Button'
import { Form } from 'vee-validate'
import * as yup from 'yup'
import Field from '@/components/Field'
import { ExclamationIcon } from '@heroicons/vue/solid'

export default {
  name: 'Login',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    Head,
    BlackWhiteTemplate,
    Button,
    Field,
    Form,
    ExclamationIcon
  },
  data () {
    return {
      loginSchema: yup.object({
        email: yup.string()
          .required(this.$t('validation.email.required')),
        password: yup.string()
          .required(this.$t('validation.password.required'))
      }),
      errorMessage: null
    }
  },
  methods: {
    login (values, actions) {
      this.$axios.post('/auth/login', {
        email: values.email,
        password: values.password
      })
        .then(res => {
          this.$store.commit('setCurrentUser', res.data.user)
          if (res.data.user.role === 'admin') {
            let redirect = '/portaal'
            if (this.$route.query.destination) {
              redirect += this.$route.query.destination
            }
            window.location.href = redirect
          } else {
            if (this.$route.query.destination) {
              this.$router.push({ path: this.$route.query.destination })
            } else {
              this.$router.push({ path: '/klantenportaal' })
            }
          }
        })
        .catch(err => {
          this.errorMessage = this.$axiosErrorHandler(err, values, actions)
        })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "head": {
      "title": "Log in - Yn'forma",
    },
    "loginIntro": "Log in to make appointments faster, to view your appointments and to consult the information we have about your pets.",
    "logIn": "Log in",
    "forgotPassword": "Forgot your password?",
    "noAccount": "No account yet?",
    "register": "Register!"
  },
  "nl": {
    "head": {
      "title": "Inloggen - Yn'forma",
    },
    "loginIntro": "Log in om gemakkelijker afspraken te maken, om uw afspraken te bekijken en om de gegevens die we over uw huisdieren hebben te raadplegen.",
    "logIn": "Inloggen",
    "forgotPassword": "Wachtwoord vergeten?",
    "noAccount": "Nog geen account?",
    "register": "Account aanmaken!"
  },
  "fr": {
    "head": {
      "title": "Connectez-vous - Yn'forma",
    },
    "loginIntro": "Connectez-vous pour faire des rendez-vous plus rapidement, pour voir vos rendez-vous et pour consulter les données que nous avons sur vos animaux.",
    "logIn": "Connectez-vous",
    "forgotPassword": "Oublié votre mot de passe ?",
    "noAccount": "Pas encore de compte ?",
    "register": "Créez votre compte!"
  }
}
</i18n>
