<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
  </Head>

  <BlackWhiteTemplate :title="$t('forgotPassword')" title-size="xs">
    <div class="container py-8">
      <p>{{ $t('forgotPasswordIntro') }}</p>

      <Form @submit="sendResetLink" :validation-schema="sendResetSchema" v-if="!message">
        <Field name="email" type="text" :label="$t('email')" autocomplete="username" class="mb-4"/>

        <div v-show="errorMessage" class="mb-4 text-red flex items-start">
          <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
          <span>{{ errorMessage }}</span>
        </div>

        <Button type="submit">{{ $t('sendResetLink') }}</Button>
      </Form>

      <p v-show="message">{{ message }}</p>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import Button from '@/components/Button'
import { Form } from 'vee-validate'
import * as yup from 'yup'
import Field from '@/components/Field'
import { ExclamationIcon } from '@heroicons/vue/solid'

export default {
  name: 'ForgotPassword',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    Head,
    BlackWhiteTemplate,
    Button,
    Field,
    Form,
    ExclamationIcon
  },
  data () {
    return {
      sendResetSchema: yup.object({
        email: yup.string()
          .required(this.$t('validation.email.required'))
          .email(this.$t('validation.email.email'))
      }),
      message: null,
      errorMessage: null
    }
  },
  methods: {
    sendResetLink (values, actions) {
      this.$axios.post('/auth/forgot-password', {
        email: values.email
      })
        .then(res => {
          actions.resetForm()
          this.message = this.$t('resetLinkSent', { email: values.email })
          this.errorMessage = null
        })
        .catch(err => {
          this.errorMessage = this.$axiosErrorHandler(err, values, actions)
          this.message = null
        })
    }
  }
}
</script>

<i18n>
{
  "en": {
    "head": {
      "title": "Password forgotten - Yn'forma",
    },
    "forgotPassword": "Password forgotten",
    "forgotPasswordIntro": "Did you forget your password? Enter your e-mail address to get a link to reset it.",
    "sendResetLink": "Send reset link",
    "resetLinkSent": "If the entered e-mail address ({email}) was correct, a reset link was sent to it. Please check your mails in order to reset your password."
  },
  "nl": {
    "head": {
      "title": "Wachtwoord vergeten - Yn'forma",
    },
    "forgotPassword": "Wachtwoord vergeten",
    "forgotPasswordIntro": "Bent u uw wachtwoord vergeten? Tik uw e-mailadres in om een link te ontvangen om een nieuw wachtwoord in te stellen.",
    "sendResetLink": "Resetlink verzenden",
    "resetLinkSent": "Indien het ingevoerde e-mailadres ({email}) correct was, werd er een resetlink naar verzonden. Gelieve uw e-mails na te kijken om een nieuw wachtwoord in te stellen."
  },
  "fr": {
    "head": {
      "title": "Mot de passe oublié - Yn'forma",
    },
    "forgotPassword": "Mot de passe oblié",
    "forgotPasswordIntro": "Avez-vous oublié votre mot de passe? Introduisez votre adresse e-mail afin de recevoir un lien pour le réinitialiser.",
    "sendResetLink": "Envoyer un lien de réinitialisation",
    "resetLinkSent": "Si l'adresse e-mail introduite ({email}) était correcte, un lien de réinitialisation y a été envoyé. Veuillez vérifier vos e-mails afin de configurer un nouveau mot de passe."
  }
}
</i18n>
