<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta name="description" :content="$t('head.meta.description')"/>
    <meta name="keywords" :content="$t('head.meta.keywords')"/>
    <meta property="og:title" :content="$t('head.title')">
    <meta property="og:description" :content="$t('head.meta.description')">
  </Head>

  <BlackWhiteTemplate :title="$t('hydrotherapy')" title-size="sm">
    <div class="container py-8">
      <div class="grid grid-cols-1 lg:grid-cols-3 items-start gap-x-10 gap-y-4 lg:gap-y-8">
        <div class="lg:col-span-2">
          <i18n-t keypath="content1" tag="p">
            <template v-slot:content1Treadmill>
              <span class="text-red font-semibold">{{ $t('content1Treadmill') }}</span>
            </template>
          </i18n-t>
          <FadingImages :imgs="[require('@/assets/dog-hydro-4b.jpg'), require('@/assets/dog-hydro-15a.jpg'), require('@/assets/dog-hydro-18a.jpg')]" :alt="$t('altDogHydrotherapy')" class="lg:hidden mb-4 w-full max-w-text h-96 rounded-lg "></FadingImages>
          <!-- <img :src="require('@/assets/dog-hydro-4a.jpg')" class="lg:hidden rounded-lg mb-4 object-cover w-full h-96" :alt="$t('altDogHydrotherapy')"> -->
          <p>{{ $t('content2') }}</p>
          <p>{{ $t('content3') }}</p>
        </div>

        <Rates class="hidden lg:block"></Rates>

        <video playsinline controls autoplay loop muted class="lg:col-span-2 rounded-lg object-cover w-full h-96 max-w-text">
          <source :src="require('@/assets/dog-hydro-14.mp4')" type="video/mp4">
        </video>

        <FadingImages :imgs="[require('@/assets/dog-hydro-4b.jpg'), require('@/assets/dog-hydro-15a.jpg'), require('@/assets/dog-hydro-18a.jpg')]" :alt="$t('altDogHydrotherapy')" class="hidden lg:block w-full max-w-text h-96 rounded-lg"></FadingImages>
        <!-- <img :src="require('@/assets/dog-hydro-4b.jpg')" class="hidden lg:block rounded-lg object-cover w-full h-96" :alt="$t('altDogHydrotherapy')"> -->
      </div>
    </div>

    <div class="container-left bg-gray-100 md:shadow-md mb-8 rounded-r-xl border-b-4 border-beige-light md:flex items-center" data-aos="fade-right" data-aos-anchor-placement="top-bottom" data-aos-duration="2500">
      <div class="order-1 md:order-2 px-4 md:pl-6 lg:pr-6 pt-8 md:pb-8 lg:py-6 mb-4 md:mb-0">
        <h3 class="text-red">{{ $t('forTitle') }}</h3>
        <p>{{ $t('for1') }}</p>
        <p>{{ $t('for2') }}</p>
      </div>
      <img :src="require('@/assets/dog-hydro-5.jpg')" class="flex-grow object-cover md:mb-0 md:order-1 md:w-2/5 lg:w-auto md:h-104 lg:h-96" :alt="$t('altDogHydrotherapy')">
    </div>

    <div class="container lg:hidden pb-8">
      <Rates></Rates>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import FadingImages from '@/components/FadingImages'

// Define rates in separate component as will need to be used twice in different places depending on screen size.
const Rates = {
  template: `
    <div class="p-4 shadow-md bg-gray-100 rounded-lg">
      <h3 class="text-red">{{ $tc('rate', 2) }}</h3>
      <h4 class="italic mb-2">{{ $t('rateATitle') }}</h4>
      <p class="text-left text-gray-500">
        {{ $t('rateA1') }}<br>
      </p>
      <h4 class="italic mb-0">{{ $t('rateBTitle') }}</h4>
      <p class="text-left text-base text-gray-500 mb-2">{{ $t('rateBNote') }}</p>
      <p class="text-left text-gray-500">
        {{ $t('rateB1') }}<br>
      </p>
      <h4 class="italic mb-0">{{ $t('rateCTitle') }}</h4>
      <p class="text-left text-base text-gray-500 mb-2">{{ $t('rateCNote') }}</p>
      <p class="text-left text-gray-500">
        {{ $t('rateC1') }}
      </p>
    </div>
  `,
  i18n: {
    messages: {
      nl: {
        "rateATitle": "Hydrotherapie",
        "rateA1": "Per sessie (30 min): €\xa040",
        "rateBTitle": "Combo hydro + fysio",
        "rateBNote": "Hydrotherapie in combinatie met laser-, oefen- en/of manuele therapie.",
        "rateB1": "Per sessie (45 min): €\xa045",
        "rateCTitle": "Eerste sessie",
        "rateCNote": "Tijdens de eerste consultatie nemen we voldoende tijd om de behandeling te bespreken.",
        "rateC1": "Eerste sessie (45-60 min): €\xa050"
      }
    }
  }
}

export default {
  name: 'Hydrotherapy',
  components: {
    Head,
    BlackWhiteTemplate,
    FadingImages,
    Rates
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Hydrotherapie - Yn'forma",
      "meta": {
        "description": "Hydrotherapie voor revalidatie en ontwikkeling van spiermassa en conditie. Gevestigd te Gooik. Zowel voor sporthonden als voor algemene gezondheid.",
        "keywords": "hydrotherapie, hondenfitness, fysiotherapie, gooik, oetingen, yn'forma, ynforma, honden, hond, huisdier, huisdieren, revalidatie"
      }
    },
    "hydrotherapy": "Hydrotherapie",
    "content1": "Hydrotherapie is een vorm van therapie waarbij uw huisdier zich onbelast voortbeweegt in water. Dit kan bij ons op een speciaal hiervoor ontwikkelde {content1Treadmill}.",
    "content1Treadmill": "onderwaterloopband",
    "content2": "Dankzij de opwaartse kracht van het water worden de spieren getraind zonder de gewrichten te belasten. De weerstand van het water zorgt voor een verbeterde spieropbouw en uithouding. Het primaire doel van de onderwaterloopband is dus de spiermassa op te bouwen en de spiertonus te verbeteren, wat leidt tot een betere stabiliteit van de gewrichten. Zo is er minder pijn en een betere functionaliteit. Het water heeft een temperatuur van ongeveer 28 graden, wat de bloedcirculatie stimuleert en soepele gewrichten oplevert.",
    "content3": "De voordelen van een onderwaterloopband zijn onbelast bewegen, hulp bij revalidatie, conditietraining, gecontroleerd bewegen, gangpatroon trainen, gewichtsreductie, spieropbouw\xa0...",
    "forTitle": "Voor wie?",
    "for1": "Hydrotherapie is van grote waarde bij revalidatie na orthopedische of neurologische chirurgie en voor honden met artrose of overgewicht. Het is ook uiterst nuttig als conditietraining voor sporthonden of showhonden. Afhankelijk van het trainingsschema kan de onderwaterloopband ingesteld worden op een bepaald waterniveau, snelheid en duur.",
    "for2": "Honden met open wonden, huidproblemen, hart- of longziekten of incontinentieproblemen en loopse teefjes komen niet in aanmerking voor het gebruik van de onderwaterloopband.",
  }
}
</i18n>
