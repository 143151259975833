<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta property="og:title" :content="$t('head.title')">
  </Head>

  <BlackWhiteTemplate :title="$t('contact')" :img="require('@/assets/rabbit-side-2.jpg')">
    <div class="container py-8">
      <div class="lg:flex relative gap-6">
        <div class="flex-1">
          <i18n-t keypath="contactIntro" tag="p">
            <template v-slot:openEveryDay>
              <span class="text-red font-semibold">{{ $t('openEveryDay') }}</span>
            </template>
          </i18n-t>
          <p>
            <a href="mailto:yne@ynforma.be">
              <MailIcon class="h-7 mr-3 inline"/>
              <span>yne@ynforma.be</span>
            </a>
          </p>
          <p>
            <a href="tel:+32-471-108-278">
              <PhoneIcon class="h-7 mr-3 inline"/>
              <span>0471/108.278</span>
            </a>
          </p>
          <p class="flex">
            <a href="https://maps.app.goo.gl/V6e7FsxBSA7WjJT16" target="_blank" rel="noreferrer" class="flex">
              <HomeIcon class="h-7 mr-3 inline"/>
              <span>
                Kerkhofstraat 10<br>
                1755 Gooik
              </span>
            </a>
          </p>

          <p>{{ $t('followSocialMedia') }}</p>
          <p>
            <a href="http://facebook.com/ynforma" target="_blank" class="inline-block" rel="noreferrer">
              <img :src="require('@/assets/facebook-logo.svg')" class="inline-block h-7 mr-3 filter-black" :alt="$t('altFacebookIcon')">
              <span>/ynforma</span>
            </a>
          </p>
          <p>
            <a href="http://instagram.com/ynforma" target="_blank" rel="noreferrer">
            <img :src="require('@/assets/instagram-logo.svg')" class="inline-block h-7 mr-3 filter-black" :alt="$t('altInstagramIcon')">
            <span>@ynforma</span>
            </a>
          </p>
        </div>

        <div class="flex-1 flex justify-center lg:justify-end">
          <iframe class="w-full max-h-full h-96 mt-8 lg:mt-0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10086.926883132373!2d4.1221152!3d50.7990803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3b14820e25565%3A0x2b172dc921789019!2sYn&#39;forma!5e0!3m2!1sen!2sbe!4v1715029464725!5m2!1sen!2sbe" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    </div>
  </BlackWhiteTemplate>
</template>

<script>
import { Head } from '@vueuse/head'
import BlackWhiteTemplate from '@/components/BlackWhiteTemplate'
import { MailIcon, PhoneIcon, HomeIcon } from '@heroicons/vue/solid'

export default {
  name: 'Contact',
  components: {
    Head,
    BlackWhiteTemplate,
    MailIcon,
    PhoneIcon,
    HomeIcon
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Contact - Yn'forma",
    },
    "contact": "Contact",
    "contactIntro": "Yn'forma is {openEveryDay}. Stuur gerust een mail of sms of telefoneer om een afspraak te maken voor één van onze diensten.",
    "openEveryDay": "open op afspraak",
    "followSocialMedia": "Volg ons ook op sociale media:"
  }
}
</i18n>
