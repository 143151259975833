import { createStore } from 'vuex'
import { axios } from '@/utils/axios'

export default createStore({
  state: {
    currentUser: undefined,
    currentCustomer: undefined
  },
  getters: {
    getCurrentUser: state => state.currentUser,
    getCurrentCustomer: state => state.currentCustomer
  },
  mutations: {
    setCurrentUser: (state, user) => {
      state.currentUser = user
    },
    setCurrentCustomer: (state, customer) => {
      state.currentCustomer = customer
    }
  },
  actions: {
    async fetchCurrentUser ({ commit }) {
      try {
        const res = await axios.get('/users/current')
        commit('setCurrentUser', res.data)
        return res.data
      } catch (err) {
        // Not logged in, or error. TODO: should set null here or only if not logged in?
        commit('setCurrentUser', null)

        // If not logged in, return user null. If other error, rethrow it.
        if (err.response.data.code === 401) {
          return null
        } else {
          throw err
        }
      }
    },
    async fetchCurrentCustomer ({ commit }) {
      try {
        const res = await axios.get('/customers/current')
        commit('setCurrentCustomer', res.data)
        return res.data
      } catch (err) {
        commit('setCurrentCustomer', null)

        // If not logged in, return customer null. If other error, rethrow it.
        if (err.response.data.code === 401) {
          return null
        } else {
          throw err
        }
      }
    }
  },
  modules: {
  }
})
