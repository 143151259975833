<template>
  <Head>
    <title>{{ $t('head.title') }}</title>
    <meta property="og:title" :content="$t('head.title')">
    <!-- TODO add other tags -->
  </Head>

  <div class="container flex justify-center items-end" :class="{ 'hidden': !$tailwind.breakpoints.md && $route.params.productId }">
    <div class="relative w-full top-10 md:max-w-xl p-4 md:p-6 shadow-md bg-beige-light rounded-lg">
      <h2 class="font-hand text-red underline decoration-3">{{ $t('shopHeader') }}</h2>
      <p class="text-justify">{{ $t('shopContent') }}</p>
    </div>
    <img :src="require('@/assets/rabbit-side-2.jpg')" class="hidden md:block h-52" alt="">
  </div>

  <div class="bg-white flex-grow" :class="{ 'pt-10': $tailwind.breakpoints.md || !$route.params.productId }">
    <div class="container py-8 flex justify-center">
      <div v-show="!$route.params.productId" class="max-w-3xl flex-grow">
        <div v-if="categories.length > 0" class="flex flex-wrap gap-4 mb-8">
          <button type="button" v-for="category in categories" @click="selectedCategory === category.id ? selectedCategory = null : selectedCategory = category.id" :key="category.id" class="bg-gray-200 hover:bg-gray-100 rounded-full px-4 py-1 flex items-center" :class="{ 'pr-3 ring ring-gray-300': selectedCategory === category.id }">
            <div>{{ category.value }}</div>
            <div v-if="selectedCategory === category.id">
              <XIcon class="h-5 ml-2"/>
            </div>
          </button>

          <button type="button" @click="selectedCategory === '' ? selectedCategory = null : selectedCategory = ''" class="bg-gray-200 hover:bg-gray-100 rounded-full px-4 py-1 flex items-center" :class="{ 'pr-3 ring ring-gray-300': selectedCategory === '' }">
            <div>{{ $t('others') }}</div>
            <div v-if="selectedCategory === ''">
              <XIcon class="h-5 ml-2"/>
            </div>
          </button>
        </div>

        <Loading :show="loading" :showingCallback="function () { products = [] }" class="w-8 mx-auto"/>
        
        <p v-if="!loading && products.length === 0" class="italic">{{ $t('noProducts') }}</p>

        <div v-for="(product, index) in products" :key="product.id" @click="$router.push({ name: 'shop', params: { productId: product.id } })" class="cursor-pointer">
          <div class="flex justify-center gap-x-6">
            <div class="h-28 w-28 flex-shrink-0">
              <img v-if="product.image" :src="`/api/files/${product.image.id}/content`" class="h-full object-cover rounded-lg" style="aspect-ratio: 1">
              <img v-else :src="require('@/assets/no-image.png')" class="h-24 mx-auto rounded-lg" style="aspect-ratio: 1">
            </div>

            <div class="flex-grow flex flex-col md:flex-row">
              <div class="flex-grow md:mr-6">
                <div>{{ product.name }}</div>
                <div v-html-sanitized-flat="product.description" class="whitespace-pre-wrap text-base text-gray-500" :class="$tailwind.breakpoints.md ? 'line-clamp-3' : 'line-clamp-2'"></div>
              </div>

              <div class="flex md:block items-center justify-between flex-shrink-0 mt-2 md:mt-0">
                <div class="text-right">
                  <span v-if="product.variants.length > 1 && product.minPrice !== product.maxPrice" class="text-sm">{{ $t('from') }}</span>
                  € {{ product.minPrice.toFixed(2).replace(/[.,]0+$/, "") }}
                </div>
                <Button :flat="!$tailwind.breakpoints.md" class="ml-4 md:ml-0 md:mt-4">
                  {{ $t('more') }}
                  <template v-slot:iconRight>
                    <ChevronRightIcon/>
                  </template>
                </Button>
              </div>
            </div>
          </div>

          <hr v-if="index < products.length - 1" class="md:ml-34 my-6">
        </div>

        <Pagination v-if="pages > 1" v-model="page" :pages="pages" :max-pages="$tailwind.breakpoints.sm ? 5 : 3" class="mt-6 w-min mx-auto"/>

        <div v-show="errorMessage" class="mb-4 text-red flex items-start">
          <ExclamationIcon class="inline h-7 mr-3 top-0.5 relative flex-shrink-0"/>
          <span>{{ errorMessage }}</span>
        </div>
      </div>

      <div v-if="$route.params.productId" class="flex-grow max-w-5xl">
        <Product :productId="$route.params.productId"/>
      </div>
    </div>
  </div>
</template>


<script>
import { Head } from '@vueuse/head'
import { ExclamationIcon, ChevronRightIcon, XIcon } from '@heroicons/vue/solid'
import Loading from '@/components/Loading'
import Button from '@/components/Button'
import Pagination from '@/components/Pagination'
import Product from '@/components/Product'

export default {
  name: 'Shop',
  inject: ['$axios', '$axiosErrorHandler'],
  components: {
    Head,
    ExclamationIcon,
    ChevronRightIcon,
    XIcon,
    Loading,
    Button,
    Pagination,
    Product
  },
  data () {
    return {
      loading: true,
      products: [],
      page: 1,
      pages: 1,
      errorMessage: null,
      categories: [],
      selectedCategory: null
    }
  },
  watch: {
    page (value) {
      this.getProducts(value)
    },
    selectedCategory (value) {
      // Trigger getProducts through page change.
      if (this.page === 1) {
        this.getProducts(1)
      } else {
        this.page = 1
      }
    }
  },
  methods: {
    getProducts (page) {
      this.loading = true
      this.$axios.get('/products', {
        params: {
          ...(this.selectedCategory !== null) && { category: this.selectedCategory },
          select: 'name description variants.price files',
          populate: 'files',
          page,
          limit: 20,
          sortBy: 'name'
        }
      })
        .then(res => {
          this.errorMessage = null
          this.loading = false
          this.pages = res.data.totalPages
          
          this.products = res.data.results
          for (const product of this.products) {
            // Select first image among files.
            product.image = product.files.find(el => el.mimeType.startsWith('image'))

            // Find min and max price of variants.
            product.minPrice = Math.min(...product.variants.map(v => v.price))
            product.maxPrice = Math.max(...product.variants.map(v => v.price))
          }
        })
        .catch(err => {
          this.loading = false
          this.errorMessage = this.$axiosErrorHandler(err)
        })
    },
    getCategories () {
      this.$axios.get('/enumerations/productCategories/values', {
        params: {
          sortBy: 'value',
          limit: 999
        }
      })
        .then(res => {
          this.categories = res.data.results
        })
        .catch(err => {
          this.$store.commit('setErrorMessage', this.$axiosErrorHandler(err))
        })
    },
  },
  mounted () {
    this.getCategories()
    this.getProducts(1)
  }
}
</script>

<i18n>
{
  "nl": {
    "head": {
      "title": "Winkel - Yn'forma"
    },
    "shopHeader": "Winkel",
    "shopContent": "Bij Yn'forma bieden we ook enkele producten aan die dikwijls van nut zijn voor onze klanten. Contacteer ons gerust indien u geïnteresseerd bent in het aanbod.",
    "noProducts": "Momenteel zijn er geen producten in het aanbod.",
    "from": "v.a.",
    "others": "Andere"
  }
}
</i18n>
